import axios from 'axios'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueOnlineProp from 'vue-online-prop'
import VueClipboard from 'vue-clipboard2'
import vuetify from './plugins/vuetify';
import moment from 'moment'
import VueGtag from "vue-gtag";
import { setTokenHeader } from './api'
import { isInStandaloneMode } from '@/utils/pwa'


Vue.prototype.$standalone = isInStandaloneMode(window)
Vue.prototype.$domain = process.env.VUE_APP_DOMAIN
Vue.prototype.$http = axios
Vue.prototype.$version = '20220607.01'
Vue.prototype.$mapboxAccessToken = 'pk.eyJ1IjoidHJpcGxlYXVnaHRkZXNpZ24iLCJhIjoiY2l2NGN4bzJ6MDBlczJ6cWxnaTNwNzMyNyJ9.oSgwGFfpQBESE608Y8fNAA'
Vue.prototype.$mapboxStyle = 'mapbox://styles/mapbox/satellite-streets-v11'
if (process.env.VUE_APP_VERSIONSUFFIX) {
  Vue.prototype.$version += process.env.VUE_APP_VERSIONSUFFIX
}

Vue.use(VueOnlineProp)
Vue.use(VueClipboard)
Vue.use(VueGtag, {
  config: { id: "UA-11923001-15" },
  appName: 'Quest',
  pageTrackerScreenviewEnabled: true,
}, router);

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).local().format('YYYY-MM-DD')
  }
})
Vue.filter('formatDatetime', function(value) {
  if (value) {
    return moment(String(value)).local().format('YYYY-MM-DD HH:mm Z')
  }
})

setTokenHeader()

// Wait for async storage to be ready before proceeding.
// https://github.com/championswimmer/vuex-persist#how-to-know-when-async-store-has-been-replaced
const waitForStorageToBeReady = async (to, from, next) => {
    await store.restored
    next()
}
router.beforeEach(waitForStorageToBeReady)

router.beforeEach((to, from, next) => {
  // If the route requires authentication, check if the user is logged in.
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.auth.isAuthenticated) {
      next({ name: 'Login', params: {next: to.fullPath}, replace: true })
    } else {
      next()
    }
  }
  // If the route requires a profile, check if the user has one.
  if (to.matched.some(record => record.meta.requiresProfile)) {
    if (!store.state.auth.isAuthenticated) {
      next({ name: 'Login', params: {next: to.fullPath}, replace: true })
    } else if (store.state.auth.profileMissing) {
      next({ name: 'ProfileMissing', replace: true })
    } else if (store.getters['auth/termsMissing'] && ! to.meta.termsNotRequired) {
      next({ name: 'TermsMissing', params: {next: to.fullPath}, replace: true })
    } else {
      next()
    }
  }
  // If the route is only for anonymous users and the user is logged in, redirect.
  else if (to.matched.some(record => record.meta.anonOnly)) {
    if (store.state.auth.isAuthenticated) {
      next({ name: 'Home' })
    } else {
      next()
    }
  } else {
    next()
  }
});

router.afterEach((to, from) => {
  // Store an abbreviated version of the previous route.
  store.dispatch('history/storePrevRoute', {name: from.name, path: from.path})
});

// eslint-disable-next-line no-unused-vars
const v = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
