import Vue from 'vue'
import VueRouter from 'vue-router'

const Author = () => import('@/views/Author.vue')
const Challenge = () => import('@/views/Challenge.vue')
const Challenges = () => import('@/views/Challenges.vue')
const EmailChange = () => import('@/views/EmailChange.vue')
const Event = () => import('@/views/Event.vue')
const Events = () => import('@/views/Events.vue')
const Home = () => import('@/views/Home.vue')
const Leaderboard = () => import('@/views/Leaderboard.vue')
const Logbook = () => import('@/views/Logbook.vue')
const Login = () => import('@/views/Login.vue')
const Logout = () => import('@/views/Logout.vue')
const MessageInbox = () => import('@/views/MessageInbox.vue')
const MessageSend = () => import('@/views/MessageSend.vue')
const MessageThread = () => import('@/views/MessageThread.vue')
const NotFound = () => import('@/views/NotFound.vue')
const Page = () => import('@/views/Page.vue')
const Participant = () => import('@/views/Participant.vue')
const PasswordChange = () => import('@/views/PasswordChange.vue')
const PasswordReset = () => import('@/views/PasswordReset.vue')
const PasswordResetConfirm = () => import('@/views/PasswordResetConfirm.vue')
const Profile = () => import('@/views/Profile.vue')
const ProfileDelete = () => import('@/views/ProfileDelete.vue')
const ProfileMissing = () => import('@/views/ProfileMissing.vue')
const Quest = () => import('@/views/Quest.vue')
const Quests = () => import('@/views/Quests.vue')
const Region = () => import('@/views/Region.vue')
const Register = () => import('@/views/Register.vue')
const Season = () => import('@/views/Season.vue')
const Settings = () => import('@/views/Settings.vue')
const ShopRedirect = () => import('@/views/ShopRedirect.vue')
const SocialProfile = () => import('@/views/SocialProfile.vue')
const Story = () => import('@/views/Story.vue')
const Team = () => import('@/views/Team.vue')
const TeamCreate = () => import('@/views/TeamCreate.vue')
const TeamEdit = () => import('@/views/TeamEdit.vue')
const Teams = () => import('@/views/Teams.vue')
const TermsMissing = () => import('@/views/TermsMissing.vue')
const Waitlist = () => import('@/views/Waitlist.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresProfile: true,
    },
  },
  {
    path: '/leaderboard/',
    name: 'Leaderboard',
    component: Leaderboard,
    meta: {
      requiresProfile: true,
      navMenuBack: true,
    },
  },
  {
    path: '/quest/',
    name: 'Quests',
    component: Quests,
    meta: {
      requiresProfile: true,
    },
  },
  {
    path: '/messages/',
    name: 'MessageInbox',
    component: MessageInbox,
    meta: {
      requiresProfile: true,
      navMenuBack: true,
    },
  },
  {
    path: '/messages/send/',
    name: 'MessageSend',
    component: MessageSend,
    meta: {
      requiresProfile: true,
      navMenuBack: true,
    },
  },
  {
    path: '/messages/:id/',
    name: 'MessageThread',
    component: MessageThread,
    meta: {
      requiresProfile: true,
      navMenuBack: true,
    },
  },
  {
    path: '/logbook/',
    name: 'Logbook',
    component: Logbook,
    meta: {
      requiresProfile: true,
    },
  },
  {
    path: '/profile/',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresProfile: true,
    },
  },
  {
    path: '/profile/:username/',
    name: 'SocialProfile',
    component: SocialProfile,
    meta: {
      requiresProfile: true,
      navMenuBack: true,
    },
  },
  {
    path: '/shop/',
    name: 'Shop',
    component: ShopRedirect,
    meta: {
      requiresProfile: true,
    },
  },
  {
    path: '/login/',
    name: 'Login',
    component: Login,
    meta: {
      anonOnly: true
    }
  },
  {
    path: '/logout/',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/register/',
    name: 'Register',
    component: Register,
    meta: {
      anonOnly: true
    },
  },
  {
    path: '/waitlist/',
    name: 'Waitlist',
    component: Waitlist,
    meta: {
      anonOnly: true
    },
  },
  {
    path: '/quest/:slug/',
    name: 'Quest',
    component: Quest,
    meta: {
      requiresProfile: true,
      navMenuBack: true,
    },
  },
  {
    path: '/participant/:id/',
    name: 'Participant',
    component: Participant,
    meta: {
      requiresProfile: true,
      navMenuBack: true,
    },
  },
  {
    path: '/region/:slug/',
    name: 'Region',
    component: Region,
    meta: {
      requiresProfile: true,
      navMenuBack: true,
    },
  },
  {
    path: '/author/:id/',
    name: 'Author',
    component: Author,
    meta: {
      requiresProfile: true,
      navMenuBack: true,
    },
  },
  {
    path: '/story/:slug/',
    name: 'Story',
    component: Story,
    meta: {
      requiresProfile: true,
      navMenuBack: true,
    },
  },
  {
    path: '/season/:slug/',
    name: 'Season',
    component: Season,
    meta: {
      requiresProfile: true,
      navMenuBack: true,
    },
  },
  {
    path: '/challenge/',
    name: 'Challenges',
    component: Challenges,
    meta: {
      requiresProfile: true,
    },
  },
  {
    path: '/challenge/:slug/',
    name: 'Challenge',
    component: Challenge,
    meta: {
      requiresProfile: true,
      navMenuBack: true,
    },
  },
  {
    path: '/team/',
    name: 'Teams',
    component: Teams,
    meta: {
      requiresProfile: true,
    },
  },
  {
    path: '/team/create/',
    name: 'TeamCreate',
    component: TeamCreate,
    meta: {
      requiresProfile: true,
      navMenuBack: true,
    },
  },
  {
    path: '/team/:id/edit/',
    name: 'TeamEdit',
    component: TeamEdit,
    meta: {
      requiresProfile: true,
      navMenuBack: true,
    },
  },
  {
    path: '/team/:id/',
    name: 'Team',
    component: Team,
    meta: {
      requiresProfile: true,
      navMenuBack: true,
    },
  },
  {
    path: '/event/',
    name: 'Events',
    component: Events,
    meta: {
      requiresProfile: true,
    },
  },
  {
    path: '/event/:id/',
    name: 'Event',
    component: Event,
    meta: {
      requiresProfile: true,
      navMenuBack: true,
    },
  },
  {
    path: '/settings/',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresProfile: true,
    },
  },
  {
    path: '/settings/delete/',
    name: 'ProfileDelete',
    component: ProfileDelete,
    meta: {
      requiresProfile: true,
      termsNotRequired: true,
    },
  },
  {
    path: '/profile-missing/',
    name: 'ProfileMissing',
    component: ProfileMissing,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/terms-missing/',
    name: 'TermsMissing',
    component: TermsMissing,
    meta: {
      requiresProfile: true,
      termsNotRequired: true,
    },
  },
  {
    path: '/password-reset/confirm/:uid/:token/',
    name: 'PasswordResetConfirm',
    component: PasswordResetConfirm,
    meta: {
      anonOnly: true
    },
  },
  {
    path: '/password/reset/',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: {
      anonOnly: true
    },
  },
  {
    path: '/password/change/',
    name: 'PasswordChange',
    component: PasswordChange,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/email/change/',
    name: 'EmailChange',
    component: EmailChange,
    meta: {
      requiresProfile: true,
    },
  },
  {
    path: '*',
    name: 'Page',
    component: Page,
    meta: {
      navMenuBack: true,
    },
  },
  {
    path: '/not-found/',
    name: 'NotFound',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
})

export default router
