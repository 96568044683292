const state = {
  routes: [],
}

const mutations = {
  'STORE_PREVROUTE': function (state, route) {
    state.routes.push(route)
    if (state.routes.length > 64) {
      state.routes.shift()
    }
  },
  'BACK': function (state) {
    // We go back by going forward. Pop the last two stored routes so that the
    // last element is accurate.
    if (state.routes.length > 2) {
      state.routes.pop()
      state.routes.pop()
    } else {
      state.routes = []
    }
  }
}

const actions = {
  storePrevRoute (context, route) {
    context.commit('STORE_PREVROUTE', route)
  },
  back (context) {
    context.commit('BACK')
  },
}

const getters = {
  prevRoute (state) {
    if (state.routes.length) {
      return state.routes[state.routes.length - 1]
    }
    return null
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
