<template>
  <svg
    class="v-icon__svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
  >
    <path d="m57.76 52.17a12.52 12.52 0 0 0 17.65 0l21-21a12.51 12.51 0 0 0 0-17.64l-9.98-9.89a12.51 12.51 0 0 0 -17.64 0l-21 21a12.52 12.52 0 0 0 0 17.65l1.66 1.66-5.55 5.54-1.66-1.66a12.52 12.52 0 0 0 -17.65 0l-20.95 21a12.51 12.51 0 0 0 0 17.64l9.93 9.93a12.51 12.51 0 0 0 17.64 0l21-21a12.52 12.52 0 0 0 0-17.65l-1.7-1.65 5.59-5.59zm-12.25 10a6.32 6.32 0 0 1 0 8.91l-18.67 18.62a6.31 6.31 0 0 1 -8.9 0l-7.64-7.64a6.31 6.31 0 0 1 0-8.9l18.7-18.67a6.32 6.32 0 0 1 8.91 0l.51.51-1.81 1.81a3.14 3.14 0 0 0 0 4.42l2.2 2.2a3.14 3.14 0 0 0 4.42 0l1.77-1.81zm9-24.26a6.32 6.32 0 0 1 0-8.91l18.65-18.7a6.31 6.31 0 0 1 8.9 0l7.64 7.64a6.31 6.31 0 0 1 0 8.9l-18.7 18.67a6.32 6.32 0 0 1 -8.91 0l-.47-.51 2-2a3.13 3.13 0 0 0 0-4.41l-2.21-2.21a3.13 3.13 0 0 0 -4.41 0l-2 2z" />
  </svg>
</template>

<script>
export default {
  name: 'ChainIcon'
}
</script>
