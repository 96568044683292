<template>
  <svg
    class="v-icon__svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 118.96"
  >
    <path d="m36.8 45.09-8.55-8.55a2.26 2.26 0 0 1 0-3.19l2.55-2.55a2.25 2.25 0 0 1 3.19 0l7.07 7.07a2.09 2.09 0 0 0 3 0l21.86-21.87a2.25 2.25 0 0 1 3.19 0l2.54 2.54a2.25 2.25 0 0 1 0 3.19l-23.36 23.36-4.29 4.25a2.09 2.09 0 0 1 -2.95 0zm45 21.07v-2.28a1.43 1.43 0 0 0 -1.43-1.43h-47.1a1.43 1.43 0 0 0 -1.43 1.43v2.28a1.43 1.43 0 0 0 1.43 1.43h47.07a1.44 1.44 0 0 0 1.45-1.42zm0 34.67v-2.29a1.43 1.43 0 0 0 -1.43-1.42h-47.1a1.43 1.43 0 0 0 -1.43 1.42v2.29a1.44 1.44 0 0 0 1.43 1.43h47.07a1.44 1.44 0 0 0 1.45-1.42zm0-17.33v-2.3a1.43 1.43 0 0 0 -1.43-1.43h-47.1a1.43 1.43 0 0 0 -1.43 1.43v2.28a1.43 1.43 0 0 0 1.43 1.43h47.07a1.43 1.43 0 0 0 1.45-1.41zm-60.39-21.73a3.3 3.3 0 0 0 -2.27 5.65 3.26 3.26 0 0 0 2.31 1 3.3 3.3 0 0 0 0-6.6zm0 17.29a3.3 3.3 0 0 0 -3.25 3.35 3.31 3.31 0 0 0 3.29 3.25 3.31 3.31 0 0 0 3.24-3.36 3.3 3.3 0 0 0 -3.27-3.24zm0 17.66a3.3 3.3 0 0 0 -3.25 3.35 3.3 3.3 0 0 0 3.29 3.25 3.3 3.3 0 0 0 0-6.6zm78.59 17.68v-109.84a4.56 4.56 0 0 0 -4.56-4.56h-90.88a4.56 4.56 0 0 0 -4.56 4.56v109.84a4.56 4.56 0 0 0 4.56 4.6h90.88a4.56 4.56 0 0 0 4.56-4.6zm-4.56-111a1.15 1.15 0 0 1 1.14 1.14v109.86a1.16 1.16 0 0 1 -1.14 1.14h-90.88a1.16 1.16 0 0 1 -1.14-1.14v-109.84a1.17 1.17 0 0 1 1.14-1.14z" />
  </svg>
</template>

<script>
export default {
  name: 'LogbookIcon'
}
</script>
