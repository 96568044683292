import polygonToLine from '@turf/polygon-to-line'
import pointToLineDistance from '@turf/point-to-line-distance'
import booleanPointinPolygon from '@turf/boolean-point-in-polygon'

// Calculate the distance between the given point and GeoJSON geometry.
function pointGeometryDistance (point, geometry) {
  let distanceKm = null
  // If the point is in the region, consider the distance to be 0.
  if (booleanPointinPolygon(point, geometry)) {
    distanceKm = 0
  } else {
    // Otherwise convert the region polygon to a line.
    // https://github.com/Turfjs/turf/issues/1743
    const regionLine = polygonToLine(geometry)
    // If the region is a multi polygon, find the closest line.
    if (regionLine.type == 'FeatureCollection') {
      for (let x = 0; x < regionLine.features.length; x++) {
        const featureDistance = pointToLineDistance(
          point,
          regionLine.features[x]
        );
        if (distanceKm == null) {
          distanceKm = featureDistance
        } else if (featureDistance < distanceKm) {
          distanceKm = featureDistance
        }
      }
      // Otherwise just get the distance to the nearest line point.
      } else {
        distanceKm = pointToLineDistance(
          point,
          regionLine
        );
      }
  }
  return distanceKm
}

// Calculate the distance between the given point and GeoJSON geometry,
// supporting a FeatureCollection.
function calcDistance (point, geometry, nullDistance) {
  let distanceKm = null
  // https://github.com/Turfjs/turf/issues/1743
  if (geometry && geometry.type == 'FeatureCollection') {
    for (let x = 0; x < geometry.features.length; x++) {
      const featureDistance = pointGeometryDistance(point, geometry.features[x])
      if (distanceKm == null) {
        distanceKm = featureDistance
      } else if (featureDistance < distanceKm) {
        distanceKm = featureDistance
      }
    }
  } else if (geometry) {
    distanceKm = pointGeometryDistance(point, geometry)
  } else if (nullDistance) {
    distanceKm = nullDistance
  }
  return distanceKm
}

export {
  pointGeometryDistance,
  calcDistance,
}
