import axios from 'axios';
import Cookie from 'js-cookie'

const allianceBaseURL = process.env.VUE_APP_APIURL

const allianceAPI = axios.create({
  baseURL: allianceBaseURL,
  timeout: 15000,
})

function setTokenHeader () {
  const accessToken = Cookie.get('token')
  if (accessToken) {
    allianceAPI.defaults.headers.common['Authorization'] = `Token ${accessToken}`
  }
}

export {
  allianceAPI,
  allianceBaseURL,
  setTokenHeader,
}
