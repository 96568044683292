import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import auth from './modules/auth'
import authors from './modules/authors'
import banners from './modules/banners'
import challenges from './modules/challenges'
import evidence from './modules/evidence'
import flagreasons from './modules/flagreasons'
import history from './modules/history'
import invitecodes from './modules/invitecodes'
import likes from './modules/likes'
import localForage from 'localforage'
import messages from './modules/messages'
import pages from './modules/pages'
import participants from './modules/participants'
import products from './modules/products'
import profiles from './modules/profiles'
import promos from './modules/promos'
import quests from './modules/quests'
import referrals from './modules/referrals'
import regions from './modules/regions'
import seasons from './modules/seasons'
import stats from './modules/stats'
import stories from './modules/stories'
import teamapplications from './modules/teamapplications'
import teaminvitations from './modules/teaminvitations'
import teams from './modules/teams'
import userlocation from './modules/userlocation'

Vue.use(Vuex)

const vuexLocal = new VuexPersist({
  key: 'vuex',
  storage: localForage,
  asyncStorage: true,
  modules: [
    'auth',
    'authors',
    'banners',
    'challenges',
    'evidence',
    'flagreasons',
    'history',
    'invitecodes',
    'likes',
    'messages',
    'pages',
    'participants',
    'products',
    'profiles',
    'promos',
    'quests',
    'referrals',
    'regions',
    'seasons',
    'stats',
    'stories',
    'teams',
    'userlocation',
  ]
})

const store = new Vuex.Store({
  modules: {
    auth,
    authors,
    banners,
    challenges,
    evidence,
    flagreasons,
    history,
    invitecodes,
    likes,
    messages,
    pages,
    participants,
    products,
    profiles,
    promos,
    quests,
    referrals,
    regions,
    seasons,
    stats,
    stories,
    teamapplications,
    teaminvitations,
    teams,
    userlocation,
  },
  plugins: [vuexLocal.plugin],
  actions: {
    reset({ commit }){
      commit("auth/RESET")
      commit("authors/RESET")
      commit("banners/RESET")
      commit("challenges/RESET")
      commit("evidence/RESET")
      commit("flagreasons/RESET")
      commit("invitecodes/RESET")
      commit("likes/RESET")
      commit("messages/RESET")
      commit("pages/RESET")
      commit("participants/RESET")
      commit("products/RESET")
      commit("profiles/RESET")
      commit("promos/RESET")
      commit("quests/RESET")
      commit("referrals/RESET")
      commit("regions/RESET")
      commit("seasons/RESET")
      commit("stats/RESET")
      commit("stories/RESET")
      commit("teamapplications/RESET")
      commit("teaminvitations/RESET")
      commit("teams/RESET")
    }
  }
})

export default store
