import generic from './generic'
import flatMap from 'lodash.flatmap'

const getDefaultState = () => {
  return {
    all: [],
    errored: false,
    loading: false,
    fetchedAt: null,
    evidenceList: [],
  }
}

const state = getDefaultState()

const mutations = {
  ...generic.mutations,
  'RESET': function (state) {
    Object.assign(state, getDefaultState())
  },
  'SET_EVIDENCE_LIST': function (state) {
    state.evidenceList = flatMap(state.all, (n) => n.participantevidence_set)
  },
}

const actions = {
  ...generic.actions,
  getAll (context) {
    return generic.getAll(context, {type: 'participant'}).then(() => context.commit('SET_EVIDENCE_LIST'))
  },
  backgroundRefresh (context) {
    return generic.getAll(context, {type: 'participant', hideLoading: true})
  },
  updateObject (context, id) {
    return generic.updateObject(context, `/quest/participant/${id}`)
  },
}

const getters = {
  ...generic.getters,
  filterByQuestID: (state) => (id) => {
    return state.all.filter(obj => obj.quest.id == id)
  },
  getByQuestID: (state) => (id) => {
    return state.all.find(obj => obj.quest.id == id)
  },
  imageEvidence (state) {
    return state.evidenceList.filter(obj => obj.image_display)
  },
  completed (state) {
    return state.all.filter(obj => obj.status == 'complete')
  },
  profilesForParticipant: (state) => (id) => {
    const participant = state.all.find(obj => obj.id == id)
    const profileList = []
    if (participant) {
      for (let i = 0; i < participant.participantprofile_set.length; i++) {
        profileList.push(participant.participantprofile_set[i].profile)
      }
    }
    return profileList
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
