<template>
  <svg
    class="v-icon__svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 180.64 100"
  >
    <path d="m93.31 100 43.69-71.69 43.64 71.69zm-62.78-.31 61.94-99.69 27.34 44.2-33 55.49zm-30.53 0 29.77-49.87 12.07 20.69-18 29.18z" />
  </svg>
</template>

<script>
export default {
  name: 'TerraIcon'
}
</script>
