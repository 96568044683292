import generic from './generic'

const getDefaultState = () => {
  return {
    all: [],
    locked: [],
    errored: false,
    loading: false,
    fetchedAt: null,
    ageLimit: 180,
  }
}

const state = getDefaultState()

const mutations = {
  ...generic.mutations,
  'RESET': function (state) {
    Object.assign(state, getDefaultState())
  },
  'SET_LOCKED': function (state, locked) {
    state.locked = locked
  },
  'ADD_LOCK': function (state, id) {
    const index = state.locked.indexOf(id)
    if (index < 0) {
      state.locked.push(id)
    }
  },
  'REMOVE_LOCK': function (state, id) {
    const index = state.locked.indexOf(id)
    if (index > -1) {
      state.locked.splice(index, 1)
    }
  },
}

const actions = {
  ...generic.actions,
  getAll (context) {
    return generic.getAll(context, {url: '/alliance/promo/'})
  },
  backgroundRefresh (context) {
    return generic.getAll(context, {url: '/alliance/promo/', hideLoading: true})
  },
  setLocked (context, locked) {
    return context.commit('SET_LOCKED', locked)
  },
  addLock (context, id) {
    return context.commit('ADD_LOCK', id)
  },
  removeLock (context, id) {
    return context.commit('REMOVE_LOCK', id)
  },
}

const getters = {
  ...generic.getters,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
