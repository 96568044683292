import generic from './generic'

const getDefaultState = () => {
  return {
    all: [],
    errored: false,
    loading: false,
    fetchedAt: null,
    next: null,
    seasonLeaderboard: null,
    ageLimit: 180,
  }
}

const state = getDefaultState()

const mutations = {
  ...generic.mutations,
  'RESET': function (state) {
    Object.assign(state, getDefaultState())
  },
  'SET_LEADERBOARD_OVERRIDE': function (state) {
    const season  = state.all.find(obj => obj.override_leaderboard == true)
    if (season) {
      state.seasonLeaderboard = season
    } else {
      state.seasonLeaderboard = null
    }
  },
}

const actions = {
  ...generic.actions,
  getAll (context) {
    return generic.getAll(context, {type: 'season'}).then(() => {
      context.commit('SET_LEADERBOARD_OVERRIDE')
    })
  },
  backgroundRefresh (context) {
    return generic.getAll(context, {type: 'season', hideLoading: true})
  },
}

const getters = {
  ...generic.getters,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
