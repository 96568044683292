<template>
  <svg
    class="v-icon__svg"
    viewBox="0 0 140 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m10 0a10 10 0 0 1 10 10 10 10 0 0 1 -10 10 10 10 0 0 1 -10-10 10 10 0 0 1 10-10zm120 100a10 10 0 0 1 -10-10 10 10 0 0 1 10-10 10 10 0 0 1 10 10 10 10 0 0 1 -10 10zm-80-100h80a10 10 0 0 1 10 10 10 10 0 0 1 -10 10h-80a10 10 0 0 1 -10-10 10 10 0 0 1 10-10zm40 100h-80a10 10 0 0 1 -10-10 10 10 0 0 1 10-10h80a10 10 0 0 1 10 10 10 10 0 0 1 -10 10zm20-60h20a10 10 0 0 1 10 10 10 10 0 0 1 -10 10h-20a10 10 0 0 1 -10-10 10 10 0 0 1 10-10zm-100 0h20a10 10 0 0 1 10 10 10 10 0 0 1 -10 10h-20a10 10 0 0 1 -10-10 10 10 0 0 1 10-10zm60 0a10 10 0 0 1 10 10 10 10 0 0 1 -10 10 10 10 0 0 1 -10-10 10 10 0 0 1 10-10z" />
  </svg>
</template>

<script>
export default {
  name: 'FilterIcon'
}
</script>
