import Cookie from 'js-cookie'
import Vue from 'vue'
import get from 'lodash.get'
import router from '@/router'
import {setTokenHeader, allianceAPI, allianceBaseURL} from '@/api'

function cookieSecure() {
  // Cookie should requires HTTPS in production, but can use HTTP otherwise.
  if (process.env.NODE_ENV == 'production') {
    return true
  }
  return false
}

const getDefaultState = () => {
  return {
    loginError: null,
    profile: null,
    profileMissing: false,
    isAuthenticated: false,
    loading: false,
    localTermsAgree: false,
    endpoints: {
      obtainToken: allianceBaseURL + '/auth/login/',
      logout: allianceBaseURL + '/auth/logout/',
    },
  }
}

const state = getDefaultState()

const mutations = {
  'RESET': function (state) {
    Object.assign(state, getDefaultState())
  },
  'TERMS_AGREE': function (state) {
    state.localTermsAgree = true
  },
  'SET_TOKEN': function (state, token) {
    Cookie.set('token', token.key, { expires: 7, secure: cookieSecure(), sameSite: 'strict' })
    state.isAuthenticated = true
    state.loginError = null
  },
  'REMOVE_TOKEN': function (state) {
    Cookie.remove('token', { expires: 7, secure: cookieSecure(), sameSite: 'strict' })
    state.isAuthenticated = false
    state.loginError = null
  },
  'SET_PROFILE': function (state, profile) {
    state.profile = profile
    state.loginError = null
    state.profileMissing = false
  },
  'REMOVE_PROFILE': function (state) {
    state.profile = null
    state.loginError = null
    state.profileMissing = false
  },
  'PROFILE_MISSING': function (state) {
    state.profileMissing = true
  },
  'LOGIN_BEGIN': function (state) {
    state.loading = true
  },
  'LOGIN_COMPLETE': function (state) {
    state.loading = false
  },
  'LOGIN_FAIL': function (state, error) {
    state.loading = false
    if (error.response && error.response.status === 400 && error.response.data && error.response.data.non_field_errors) {
      state.loginError = error.response.data.non_field_errors[0]
    } else if (error.response && error.response.status === 429) {
      state.loginError = 'You have made too many requests.'
    } else if (error.code && error.code == "ECONNABORTED") {
      state.loginError = 'Login attempt timed out. Check your network connection and try again.'
    } else {
      state.loginError = 'Failed to login.'
    }
    if (state.loginError == 'E-mail is not verified.') {
      state.loginError += ' Please check your email for a confirmation link.'
    }
  },
  'UPDATE_CLOSED_BANNER_LIST': function (state, list) {
    if (state.profile) {
      state.profile.closed_banner_id_list = list
    }
  },
}

const actions = {
  updateClosedBannerList ( context, {response}) {
    if (response && response.data && response.data.closed_banner_id_list) {
      context.commit('UPDATE_CLOSED_BANNER_LIST', response.data.closed_banner_id_list)
    }
  },
  login (context, {credentials, redirect}) {
    context.commit('LOGIN_BEGIN')
    return Vue.prototype.$http.post(
      context.state.endpoints.obtainToken,
      credentials,
      {timeout: 5000}
    )
      .then(async (response) => {
        // If the response doesn't include a key, throw an exception.
        if (!get(response, 'data.key')) {
          throw 'Unexpected login response.'
        }
        context.commit('SET_TOKEN', response.data)
        setTokenHeader()
        await actions.getProfile(context)
        if (redirect) {
          router.push(redirect)
        }
        context.commit('LOGIN_COMPLETE')
      })
      .catch((error) => context.commit('LOGIN_FAIL', error))
  },
  getProfile (context) {
    if (context.state.isAuthenticated) {
      return allianceAPI.get('/alliance/profile/me/')
        .then((response) => {
          context.commit('SET_PROFILE', response.data)
        })
        .catch((error) => {
          console.log('fail profile')
          console.log(error)
          if (error.response && error.response.status === 401) {
            console.log('token is invalid or has expired')
            context.commit('REMOVE_TOKEN')
            context.commit('REMOVE_PROFILE')
          } else if (error.response && error.response.status === 404) {
            context.commit('PROFILE_MISSING')
          }
        })
    }
  },
  logout (context) {
    return allianceAPI.post(context.state.endpoints.logout)
      .finally(() => {
        context.dispatch('reset', null, { root: true })
      })
  },
  termsAgree (context) {
    context.commit('TERMS_AGREE')
  },
}

const getters = {
  hasProfile (state) {
    return state.isAuthenticated && state.profile
  },
  termsMissing (state) {
    return !state.localTermsAgree && state.profile && state.profile.terms_required && !state.profile.has_accepted_terms
  },
  getChallengeAchievementBySlug: (state) => (slug) => {
    return state.profile.achievements.find(obj => obj.challenge.slug === slug)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
