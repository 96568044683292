<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
    >
      <v-list dense>
        <v-list-item
          v-for="item in navItems"
          :key="item.title"
          link
          :to="item.to"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div
        align="center"
        class="brand-escape text-overline pt-12"
      >
        <div class="logomark mb-3">
          <a href="https://tripleaughtdesign.com">
            <v-icon
              x-large
              color="#757575"
            >
              $vuetify.icons.tripleAughtDesign
            </v-icon>
          </a>
        </div>
        <div>
          <a href="https://tripleaughtdesign.com">Triple Aught Design</a>
        </div>
        <div class="privacy pt-3">
          <a href="https://tripleaughtdesign.com/pages/privacy-policy">Privacy Policy</a>
        </div>
        <div
          class="version pt-3"
        >
          {{ $version }}
        </div>
      </div>
    </v-navigation-drawer>

    <v-app-bar
      app
      clipped-left
      dense
    >
      <v-app-bar-nav-icon
        @click.stop="navMenuAction"
      >
        <v-icon>{{ navMenuIcon }}</v-icon>
      </v-app-bar-nav-icon>
      <v-col>
        <v-toolbar-title class="text-center">
          <router-link :to="{name: 'Home'}">
            <v-icon size="30">
              $vuetify.icons.quest
            </v-icon>
          </router-link>
        </v-toolbar-title>
      </v-col>
      <div v-if="profile">
        <router-link
          :to="{name: 'Profile'}"
        >
          <v-badge
            :value="numUnread"
            color="red"
            dot
            overlap
            left
            avatar
          >
            <v-avatar
              size="40px"
              link
            >
              <img
                :src="profile.avatar_thumbnail_url"
                :alt="profile.user + ' avatar'"
              >
            </v-avatar>
          </v-badge>
        </router-link>
      </div>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-alert
          id="updateAvailable"
          icon="$reload"
          style="display: none"
        >
          An update is available. <a @click="reloadPage">Please reload the page</a>.
        </v-alert>
        <v-snackbar
          v-model="locationSnackbar"
          timeout="12000"
        >
          {{ locationError }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="error"
              text
              v-bind="attrs"
              @click="locationSnackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <router-view :key="$route.path" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'App',
  data: () => ({
    drawer: null,
    quickPoll: null,
    longPoll: null,
    locationSnackbar: false,
  }),
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
      isAuthenticated: state => state.auth.isAuthenticated,
      prevRoute: state => state.history.prevRoute,
      numUnread: state => state.messages.numUnread,
      locationError: state => state.userlocation.error,
    }),
    ...mapGetters({
      prevRoute: 'history/prevRoute',
    }),
    navItems() {
      const navList = new Array

      if (this.isAuthenticated) {
        navList.push({
          title: 'Home',
          to: '/',
          icon: '$home',
        })
        navList.push({
          title: 'Logbook',
          to: '/logbook/',
          icon: '$logbook',
        })
        navList.push({
          title: 'Quests',
          to: '/quest/',
          icon: '$quest',
        })
        navList.push({
          title: 'Challenges',
          to: '/challenge/',
          icon: '$challenge',
        })
        if (this.profile && this.profile.allow_teams) {
          navList.push({
            title: 'Teams',
            to: '/team/',
            icon: '$team',
          })
        }
        if (this.profile && this.profile.allow_events) {
          navList.push({
            title: 'Events',
            to: '/event/',
            icon: '$calendar',
          })
        }
        navList.push({
          title: 'Shop',
          to: '/shop/',
          icon: '$shop',
        })
        navList.push({
          title: 'Settings',
          to: '/settings/',
          icon: '$config',
        })
        navList.push({
          title: 'Logout',
          to: '/logout/',
          icon: '$logout',
        })
      } else {
        navList.push({
          title: 'Login',
          to: '/login/',
          icon: '$login',
        })
        navList.push({
          title: 'Register',
          to: '/register/',
          icon: '$register',
        })
      }
      return navList
    },
    isNavMenuBack() {
      // If the current route requests it, and a previous route is stored, the
      // nav menu button should function as a back button.
      if (this.$route.meta.navMenuBack && this.prevRoute) {
        return true
      }
      return false
    },
    navMenuIcon() {
      if (this.isNavMenuBack) {
        return '$arrowLeft'
      }
      return '$menu'
    }
  },
  watch: {
    locationError: function (value) {
      if (!value) {
        this.locationSnackbar = false
      } else {
        this.locationSnackbar = true
      }
    },
    isAuthenticated: function (newValue) {
      // If isAuthenticated changes from false to true, update all the things.
      if (newValue) {
        this.updateAllTheThings()
      // If it changed to false, and the current route requires authentication,
      // redirect to the login page.
      } else if (this.$route.meta.requiresProfile || this.$route.meta.requiresAuth) {
        this.$router.push({name: 'Login', params: {next: this.$route.path}, replace: true})
      }
    },
  },
  created: function() {
    this.autoRefresh()
  },
  beforeDestroy: function() {
    clearInterval(this.quickPoll)
    clearInterval(this.longPoll)
  },
  methods: {
    reloadPage() {
      window.location.reload()
    },
    navMenuAction() {
      if (this.isNavMenuBack) {
        this.$router.push(this.prevRoute.path)
        this.$store.dispatch('history/back')
        return
      }
      return this.drawer = !this.drawer
    },
    updateAllTheThings() {
      this.$store.dispatch('participants/getAll')
      this.$store.dispatch('messages/getAll')
      this.$store.dispatch('likes/getAll')
      this.$store.dispatch('regions/getAll')
      this.$store.dispatch('seasons/getAll')
    },
    autoRefresh() {
      // Refresh all the things immediately when authed.
      if (this.isAuthenticated) {
        this.updateAllTheThings()
      }
      // Refresh some things frequently.
      this.quickPoll = setInterval(() => {
        if (this.isAuthenticated && this.$online) {
          this.$store.dispatch('auth/getProfile')
        }
      }, 1*60*1000)
      // Refresh other things less frequently.
      this.longPoll = setInterval(() => {
        if (this.isAuthenticated) {
          this.updateAllTheThings()
        }
      }, 5*60*1000)
    },
  },
}
</script>

<style lang="scss">
@import 'src/assets/style.scss';
@import 'src/assets/banners.scss';
</style>
