import generic from './generic'
import orderBy from 'lodash.orderby'

const getDefaultState = () => {
  return {
    all: [],
    unread: [],
    errored: false,
    loading: false,
    fetchedAt: null,
    numUnread: 0,
  }
}

const state = getDefaultState()

const mutations = {
  ...generic.mutations,
  'RESET': function (state) {
    Object.assign(state, getDefaultState())
  },
  'COUNT_UNREAD': function (state) {
    state.unread = state.all.filter(x => x.unread)
    state.numUnread = state.unread.length
  }
}

const actions = {
  ...generic.actions,
  getAll (context) {
    return generic.getAll(context, {url: '/alliance/messages/threads/'}).then(() => context.commit('COUNT_UNREAD'))
  },
  backgroundRefresh (context) {
    return generic.getAll(context, {url: '/alliance/messages/threads/', hideLoading: true})
  },
}

const getters = {
  ...generic.getters,
  orderByLatestMessage (state) {
    return orderBy(state.all, ['thread.latest_message.sent_at'], 'desc')
  },
  unreadMessageList (state) {
    return orderBy(state.unread, ['thread.latest_message.sent_at'], 'desc')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
