import generic from './generic'

const getDefaultState = () => {
  return {
    all: [],
    errored: false,
    loading: false,
    fetchedAt: null,
    next: null,
    ageLimit: 180,
  }
}

const state = getDefaultState()

const mutations = {
  ...generic.mutations,
  'RESET': function (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  ...generic.actions,
  getAll (context) {
    return generic.getAll(context, {type: 'author'})
  },
  backgroundRefresh (context) {
    return generic.getAll(context, {type: 'author', hideLoading: true})
  },
}

const getters = {
  ...generic.getters,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
