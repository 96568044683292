<template>
  <svg
    class="v-icon__svg"
    viewBox="0 0 41.4 41.4"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g><path d="m35 0c3.4 0 6.2 2.8 6.2 6.2v28.9c0 3.4-2.8 6.2-6.2 6.2h-29c-3.4 0-6.2-2.8-6.2-6.2v-28.9c0-3.4 2.8-6.2 6.2-6.2zm0 1.2h-28.8c-2.8 0-5 2.2-5 5v28.9c0 2.8 2.2 5 5 5h28.8c2.8 0 5-2.2 5-5v-28.9c.2-2.7-2.2-5-5-5z" /><path d="m20.6 14.3 8.2 14.3h-16.4zm0 2.3-6.3 10.9h12.6z" /><path d="m20.1 22v-11.9h1.1v11.9z" /><path d="m21.9 25.2.7-.9 10.1 5.9-.5 1z" /><path d="m8.5 30.2 10.2-5.9.6.9-10.3 6z" /><path d="m18.1 23.9c0-1.4 1.1-2.6 2.5-2.6 1.4 0 2.6 1.1 2.6 2.6 0 1.4-1.1 2.6-2.5 2.6-1.4-.1-2.6-1.3-2.6-2.6zm1.1 0c0 .8.6 1.4 1.4 1.4.8 0 1.4-.6 1.4-1.4 0-.8-.6-1.4-1.4-1.4-.8 0-1.3.6-1.4 1.4z" /><path d="m20.6 11.2 1.5.6-1.5-3.5-1.5 3.5z" /><path d="m31.6 30.2-1.3 1 3.8.5-2.3-3.1z" /><path d="m9.7 30.2 1.3 1-3.8.5 2.3-3.1z" /></g>
  </svg>
</template>

<script>
export default {
  name: 'TripleAughtDesign'
}
</script>
