import Vue from 'vue'
import booleanPointinPolygon from '@turf/boolean-point-in-polygon'
import generic from './generic'
import orderBy from 'lodash.orderby'
import { calcDistance } from '@/utils/geo'

const getDefaultState = () => {
  return {
    all: [],
    details: [],
    errored: false,
    loading: false,
    fetchedAt: null,
    next: null,
    ageLimit: 180,
  }
}

const state = getDefaultState()

const mutations = {
  ...generic.mutations,
  'RESET': function (state) {
    Object.assign(state, getDefaultState())
  },
  'CALC_DISTANCE': function (state, rootState) {
    const point = rootState.userlocation.coordinates.point
    for (let i = 0; i < state.all.length; i++) {
      const current = state.all[i]
      const distanceKm = calcDistance(point, current.geo)
      Vue.set(state.all[i], 'userDistance', distanceKm)
    }
  },
}

const actions = {
  ...generic.actions,
  getAll (context) {
    return generic.getAll(context, {type: 'region'})
  },
  backgroundRefresh (context) {
    return generic.getAll(context, {type: 'region', hideLoading: true})
  },
  calcDistance (context) {
    return context.commit('CALC_DISTANCE', context.rootState)
  },
}

const getters = {
  ...generic.getters,
  getPointRegion: (state) => (point) => {
    for (let i = 0; i < state.all.length; i++) {
      const currentRegion = state.all[i]
      if ( booleanPointinPolygon(point, currentRegion.geo) ) {
        return currentRegion
      }
    }
    return null
  },
  getClosest (state) {
    const sorted_by_distance = state.all.sort(function(a, b) {
      // If the user is in two regions, return the smallest.
      if (a.userDistance === b.userDistance) {
        return a.geo_area - b.geo_area;
      }
      return a.userDistance > b.userDistance ? 1 : -1;
    })
    return sorted_by_distance[0]
  },
  orderByPoints (state) {
    const regions = state.all.filter(x => x.points_earned)
    return orderBy(regions, ['points_earned'], 'desc')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
