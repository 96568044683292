import generic from './generic'
import { allianceAPI } from '@/api'
import { replaceOrAppend } from '@/utils/lists'

const getDefaultState = () => {
  return {
    all: [],
    team: [],
    errored: false,
    loading: false,
    fetchedAt: null,
    next: null,
    ageLimit: 180,
  }
}

const state = getDefaultState()

const mutations = {
  ...generic.mutations,
  'RESET': function (state) {
    Object.assign(state, getDefaultState())
  },
  'SET_FORTEAM': function (state, data) {
    replaceOrAppend(state.team, data)
  },
}

const actions = {
  ...generic.actions,
  getAll (context) {
    return generic.getAll(context, {url: '/quest/team/invitation/'})
  },
  backgroundRefresh (context) {
    return generic.getAll(context, {url: '/quest/team/invitation/', hideLoading: true})
  },
  loadForTeam (context, teamId) {
    return allianceAPI.get(`/quest/team/invitation/?team=${teamId}`)
      .then((response) => {
        const entry = {
          id: teamId,
          invitation: response.data,
        }
        context.commit('SET_FORTEAM', entry)
      })
      .catch((error) => {
        console.log('error getting for team!')
        console.log(error.response)
      })
  },
}

const getters = {
  ...generic.getters,
  getForTeamByID: (state) => (id) => {
    const found = state.team.find(obj => obj.id == id)
    if (found) {
      return found.invitation
    }
    return []
  },
  getMyInviteForTeam: (state) => (id) => {
    return state.all.find(obj => obj.team == id)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
