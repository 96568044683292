<template>
  <svg
    class="v-icon__svg"
    viewBox="0 0 500 431.73"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m250 0-49.33 85.45h98.66z" />
      <path d="m49.33 346.28-49.33 85.45h98.67z" />
      <path d="m450.67 346.28-49.34 85.45h98.67z" />
      <circle
        cx="249.53"
        cy="284.23"
        r="50"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'QuestIcon'
}
</script>
