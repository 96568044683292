import findIndex from 'lodash.findindex'

function replaceOrAppend(array, replacementObject) {
  // Match the replacement object to an existing object by its ID.
  const index = findIndex(array, {id: replacementObject.id})
  // If it was found, replace the object in the list.
  if (index > -1) {
    array.splice(index, 1, replacementObject)
  // Otherwise append it to the end of the list.
  } else {
    array.push(replacementObject)
  }
  return array
}


export {
  replaceOrAppend,
}
