<template>
  <svg
    class="v-icon__svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 117.17"
  >
    <path d="m91.9 35.79h-8.46c-.18-19.79-14.72-35.79-32.64-35.79s-32.46 16-32.64 35.79h-10.07a3.16 3.16 0 0 0 -3.15 3l-4.94 75.01a3.16 3.16 0 0 0 3.15 3.37h93.69a3.16 3.16 0 0 0 3.16-3.37l-4.95-75.06a3.15 3.15 0 0 0 -3.15-2.95zm-62.66-11.53a29.34 29.34 0 0 1 5.65-9.33 21.52 21.52 0 0 1 15.91-7.63 21.55 21.55 0 0 1 15.91 7.63 29.66 29.66 0 0 1 5.66 9.33 33.42 33.42 0 0 1 2.15 11.53h-47.44a33.2 33.2 0 0 1 2.16-11.53z" />
  </svg>
</template>

<script>
export default {
  name: 'ShopIcon'
}
</script>
