import VueGeolocation from 'vue-browser-geolocation'
import { point } from '@turf/helpers'

const state = {
  coordinates: null,
  error: null,
}

const mutations = {
  'GET': function (state, coordinates) {
    state.coordinates = coordinates;
    state.coordinates.point = point([coordinates.lng, coordinates.lat])
  },
  'FAIL': function (state, error) {
    console.error(error)
    state.coordinates = null;
    state.error = "Failed to fetch location."
    if (error == "no position access") {
      state.error += " Please check your permissions."
    }
  },
  'RESET_ERROR': function (state) {
    state.error = null;
  },
}

const actions = {
  get (context) {
    context.commit('RESET_ERROR')
    return VueGeolocation.getLocation({
      timeout: 6000,
    })
      .then((coordinates) => context.commit('GET', coordinates))
      .catch((error) => context.commit('FAIL', error))
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
