import Vue from 'vue'
import get from 'lodash.get'
import orderBy from 'lodash.orderby'
import { allianceBaseURL, allianceAPI } from '@/api'
import { matchArray } from 'searchjs'
import { replaceOrAppend } from '@/utils/lists'

function updateObject (context, url) {
  // If we are offline, return an empty promise without attempting to hit the
  // API.
  if (!Vue.prototype.$online) {
    return Promise.resolve()
  }
  context.state.errored = false
  context.state.loading = true
  return allianceAPI.get(url)
    .then((response) => {
      const replacementObject = response.data
      context.commit('REPLACE', replacementObject)
    })
    .catch((error) => context.commit('API_FAIL', error))
    .finally(() => context.commit('RESET_LOADING'))
}

function getAll (context, params) {
  let updateOnly = false
  if (params.updateOnly) {
    updateOnly = true
  }
  // If we are offline, return an empty promise without attempting to hit the
  // API.
  if (!Vue.prototype.$online) {
    return Promise.resolve()
  }
  // If the model has an age limit, and the last fetch is within it, return an
  // empty promise without attempting to hit the API.
  if (context.state.ageLimit && context.state.fetchedAt && !context.state.errored && !updateOnly) {
    const fetchedAt = Date.parse(context.state.fetchedAt)
    const current = new Date()
    const seconds = (current - fetchedAt) / 1000
    if (seconds < context.state.ageLimit) {
      return Promise.resolve()
    }
  }
  context.state.errored = false
  if (!params.hideLoading) {
    context.state.loading = true
  }
  let url = params.url
  if (params.type) {
    context.state.loading = true
    url = `/quest/${params.type}/`
  }
  if (params.queryString) {
    url += params.queryString
  }
  return allianceAPI.get(url)
    .then((response) => context.commit('GET_ALL', {response, updateOnly}))
    .catch((error) => context.commit('API_FAIL', error))
    .finally(() => context.commit('RESET_LOADING'))
}

export default {
  getAll: getAll,
  updateObject: updateObject,
  mutations: {
    'GET_ALL': function (state, {response, updateOnly}) {
      let data
      // If the data is paginated, the results will be under the results key.
      if (response.data.results) {
        data = response.data.results
      // Otherwise the results is the data.
      } else {
        data = response.data
      }
      // If an update was not requested, replace the entire state.
      if (!updateOnly) {
        state.all = data
      // Otherwise check if each response object should be appended to the
      // state, or if it should replace an existing object.
      } else {
        for (let i = 0; i < data.length; i++) {
          let currentObj = data[i]
          // Check if an object with the given ID already exists in the state.
          let index = state.all.findIndex(obj => obj.id === currentObj.id)
          // If it exists, overwrite the existing object by slicing the array.
          if (index > -1) {
            state.all = [
              ...state.all.slice(0, index),
              currentObj,
              ...state.all.slice(index + 1)
            ]
          // Otherwise just add the new object to the end of the array.
          } else {
            state.all.push(currentObj)
          }
        }
      }
      // Store the URL to the next page with the API base URL stripped out.
      if (response.data.next) {
        state.next = response.data.next.replace(allianceBaseURL, '')
      } else {
        state.next = null
      }
      state.fetchedAt = new Date()
    },
    'CLEAR_ALL': function (state) {
      const all = state.all
      all.splice(0, all.length)
    },
    'API_FAIL': function (state, error) {
      console.error(error)
      state.errored = true
    },
    'RESET_LOADING': function (state) {
      state.loading = false
    },
    'REPLACE': function (state, replacementObject) {
      replaceOrAppend(state.all, replacementObject)
    },
    'SET_DETAILS': function (state, details) {
      replaceOrAppend(state.details, details)
    },
  },
  getters: {
    filter: (state) => (filterObj) => {
      //console.log(`filter: ${JSON.stringify(filterObj)}`)
      const result = state.all.filter(function (obj) {
        for (const key in filterObj) {
          const keySplit = key.split('__')
          const property = keySplit[0]
          const modifier = keySplit[1]
          let filterProperty = filterObj[key]
          // skip nullish
          if (!filterProperty) {
            continue
          }
          let objProperty = get(obj, property)
          if (modifier == 'icontains') {
            objProperty = objProperty.toLowerCase()
            filterProperty = filterProperty.toLowerCase()
            if (!objProperty.includes(filterProperty)) {
              return false
            }
          } else if (objProperty !== filterProperty) {
            return false
          }
        }
        return true
      })
      return result
    },
    filterByKey: (state) => (key, value) => {
      if (!value) {
        return state.all
      }
      const normalizedValue = value.toLowerCase()
      const result = state.all.filter(obj => obj[key].toLowerCase().includes(normalizedValue))
      return result
    },
    search: (state) => (terms) => {
      const jsql = {_join: "AND", terms: terms}
      const results = matchArray(state.all, jsql);
      return results
    },
    getByAttribute: (state) => (lookup) => {
      return state.all.find(obj => obj[lookup.key] == lookup.value)
    },
    getBySlug: (state) => (slug) => {
      return state.all.find(obj => obj.slug === slug)
    },
    getByID: (state) => (id) => {
      return state.all.find(obj => obj.id == id)
    },
    getDetailsByID: (state) => (id) => {
      return state.details.find(obj => obj.id == id)
    },
    getDetailsBySlug: (state) => (slug) => {
      return state.details.find(obj => obj.slug == slug)
    },
    getDetailsByAttribute: (state) => (lookup) => {
      return state.details.find(obj => obj[lookup.key] == lookup.value)
    },
    count (state) {
      return state.all.length
    },
    latest (state) {
      return orderBy(state.all, ['created'], 'desc')
    },
  },
  actions: {
    reset (context) {
      context.commit('RESET')
    },
    replace (context, {replacementObject}) {
      context.commit('REPLACE', replacementObject)
    },
    clearAll (context) {
      context.commit('CLEAR_ALL')
    },
    loadNextPage (context) {
      getAll(context, {url: context.state.next, updateOnly: true, hideLoading: true})
    },
    setDetails (context, {details}) {
      context.commit('SET_DETAILS', details)
    },
    getDetails (context, url) {
      return allianceAPI.get(url)
        .then((response) => {
          context.commit('SET_DETAILS', response.data)
        })
        .catch((error) => {
          console.log('error getting details!')
          console.log(error.response)
        })
    },
  },
}
