import Vue from 'vue'
import flatMap from 'lodash.flatmap'
import generic from './generic'
import orderBy from 'lodash.orderby'
import uniqBy from 'lodash.uniqby'
import { calcDistance } from '@/utils/geo'

const getDefaultState = () => {
  return {
    all: [],
    details: [],
    locked: [],
    errored: false,
    loading: false,
    fetchedAt: null,
    ageLimit: 180,
    next: null,
    questTypes: null,
    questSkills: null,
  }
}

const state = getDefaultState()

const mutations = {
  ...generic.mutations,
  'RESET': function (state) {
    Object.assign(state, getDefaultState())
  },
  'SET_QUEST_TYPES': function (state) {
    let questTypes = [... state.all.map(x => x.quest_type)]
    state.questTypes = uniqBy(questTypes, obj => obj.id)
  },
  'SET_QUEST_SKILLS': function (state) {
    let questSkills = flatMap(state.all, (n) => n.skills)
    state.questSkills = uniqBy(questSkills, obj => obj.id)
  },
  'SET_LOCKED': function (state, locked) {
    state.locked = locked
  },
  'ADD_LOCK': function (state, id) {
    const index = state.locked.indexOf(id)
    if (index < 0) {
      state.locked.push(id)
    }
  },
  'REMOVE_LOCK': function (state, id) {
    const index = state.locked.indexOf(id)
    if (index > -1) {
      state.locked.splice(index, 1)
    }
  },
  'CALC_DISTANCE': function (state, rootState) {
    const point = rootState.userlocation.coordinates.point
    for (let i = 0; i < state.all.length; i++) {
      const current = state.all[i]
      const distanceKm = calcDistance(point, current.arena_geo)
      Vue.set(state.all[i], 'userDistance', distanceKm)
    }
  },
}

const actions = {
  ...generic.actions,
  getAll (context) {
    return generic.getAll(context, {type: 'quest'}).then(() => {
      context.commit('SET_QUEST_TYPES')
    }).then(() => {
      context.commit('SET_QUEST_SKILLS')
    })
  },
  getFiltered (context, filter) {
    let queryString = '?'
    for (const key in filter) {
      queryString += `${key}=${filter[key]}&`
    }
    return generic.getAll(context, {type: 'quest', queryString: queryString})
  },
  backgroundRefresh (context) {
    return generic.getAll(context, {type: 'quest', hideLoading: true})
  },
  setLocked (context, locked) {
    return context.commit('SET_LOCKED', locked)
  },
  addLock (context, id) {
    return context.commit('ADD_LOCK', id)
  },
  removeLock (context, id) {
    return context.commit('REMOVE_LOCK', id)
  },
  calcDistance (context) {
    return context.commit('CALC_DISTANCE', context.rootState)
  },
}

const getters = {
  ...generic.getters,
  latestForRegion: (state) => (regionId) => {
    return orderBy(state.all.filter(obj => obj.hide_if_locked == false && obj.region.id === regionId), ['created'], 'desc')
  },
  latestExcludingRegion: (state) => (regionId) => {
    return orderBy(state.all.filter(obj => obj.hide_if_locked == false && obj.region.id !== regionId), ['created'], 'desc')
  },
  sortByDistance (state) {
    const sorted_by_distance = state.all.filter(obj => obj.userDistance !== null).sort(function(a, b) {
      // If the user is in two areas, return the smallest.
      if (a.userDistance === b.userDistance) {
        return a.arena_area - b.arena_area;
      }
      return a.userDistance > b.userDistance ? 1 : -1;
    })
    return sorted_by_distance
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
