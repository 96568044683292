import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import ChainIcon from '@/icons/ChainIcon.vue'
import FilterIcon from '@/icons/FilterIcon.vue'
import LogbookIcon from '@/icons/LogbookIcon.vue'
import QuestIcon from '@/icons/QuestIcon.vue'
import ShopIcon from '@/icons/ShopIcon.vue'
import TeamIcon from '@/icons/TeamIcon.vue'
import TerraIcon from '@/icons/TerraIcon.vue'
import TripleAughtDesign from '@/icons/TripleAughtDesign.vue'
import { mdiCancel, mdiCalendar, mdiClockOutline, mdiFlag, mdiPencil, mdiHelpCircle, mdiDeleteOutline, mdiTriforce, mdiMapOutline, mdiChartTimelineVariant, mdiPlus, mdiThumbUp, mdiThumbUpOutline, mdiCheck, mdiHeart, mdiLock, mdiHeartOutline, mdiShareVariant, mdiClose, mdiNearMe, mdiCached, mdiHome, mdiCog, mdiLogout, mdiLogin, mdiAccountPlus, mdiArrowLeft, mdiMenu, mdiMessageOutline } from '@mdi/js'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
    values: {
      quest: {
        component: QuestIcon,
      },
      tripleAughtDesign: {
        component: TripleAughtDesign,
      },
      shop: {
        component: ShopIcon,
      },
      logbook: {
        component: LogbookIcon,
      },
      team: {
        component: TeamIcon,
      },
      chain: {
        component: ChainIcon,
      },
      terra: {
        component: TerraIcon,
      },
      filter: {
        component: FilterIcon,
      },
      arrowLeft: mdiArrowLeft,
      calendar: mdiCalendar,
      cancel: mdiCancel,
      challenge: mdiTriforce,
      check: mdiCheck,
      clock: mdiClockOutline,
      close: mdiClose,
      config: mdiCog,
      delete: mdiDeleteOutline,
      edit: mdiPencil,
      flag: mdiFlag,
      heart: mdiHeart,
      heartOutline: mdiHeartOutline,
      help: mdiHelpCircle,
      home: mdiHome,
      like: mdiThumbUpOutline,
      liked: mdiThumbUp,
      location: mdiNearMe,
      lock: mdiLock,
      login: mdiLogin,
      logout: mdiLogout,
      map: mdiMapOutline,
      menu: mdiMenu,
      message: mdiMessageOutline,
      plus: mdiPlus,
      register: mdiAccountPlus,
      reload: mdiCached,
      share: mdiShareVariant,
      stats: mdiChartTimelineVariant,
    },
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#434D36',
      }
    }
  },
});
