<template>
  <svg
    class="v-icon__svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20.526367 20.37291"
  >
    <path
      d="m118.90919 155.58533c0-1.97908-.63764-3.81265-1.71714-5.30754.23812-.45509.3757-.97102.3757-1.51871 0-1.80975-1.46579-3.27554-3.27554-3.27554-1.02923 0-1.94468.47625-2.54529 1.21708-.62177-.13494-1.26471-.20637-1.92617-.20637-3.89731 0-7.23106 2.46856-8.51958 5.92137-1.640418.17727-2.918348 1.56633-2.918348 3.25438 0 1.71185 1.31498 3.11679 2.989788 3.25966 1.33614 3.36021 4.61698 5.74411 8.44814 5.74411.62178 0 1.23032-.0635 1.81505-.18257.59531.8255 1.56104 1.36525 2.65641 1.36525 1.80975 0 3.27554-1.46579 3.27554-3.27554 0-.58737-.1561-1.13771-.42862-1.6166 1.11125-1.50548 1.77006-3.3655 1.77006-5.37898zm-9.08844 7.23635c-2.93422 0-5.46629-1.75683-6.60135-4.27302 1.01865-.55562 1.71185-1.63512 1.71185-2.87866 0-1.26736-.72231-2.36538-1.77535-2.90778 1.10331-2.59291 3.67506-4.41589 6.66485-4.41589.41275 0 .82021.037 1.21444.10319-.0106.10054-.0159.20373-.0159.30956 0 1.80975 1.46579 3.27554 3.27554 3.27554.57414 0 1.11389-.14817 1.58221-.40746.74612 1.13771 1.18268 2.49767 1.18268 3.95817 0 1.51606-.46831 2.92364-1.27 4.08781-.44979-.23019-.95514-.36512-1.49489-.36512-1.80975 0-3.27554 1.46579-3.27554 3.27554 0 .045.005.09.008.13758-.39422.0661-.79639.10054-1.2065.10054z"
      transform="translate(-98.382822 -145.48354)"
    />
  </svg>
</template>

<script>
export default {
  name: 'TeamIcon'
}
</script>
